html, body, #root {
    height: 100%;
}

body, #root {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

.navbar-brand {
    padding: 10px 15px;
}

.navbar-brand img {
    height: 32px;
}

.nav .btn-sign-up {
    padding: 7px 10px;
    background-color: #e69635;
    color: #fff;
    border-radius: 25px;
    transition: 0.3s;
}

.nav .btn-sign-up:hover {
    background-color: #ebab5d;
}

.editor {
    margin:15px 0;
}

.icon-link {
    cursor: pointer;
}

.icon-link:hover {
    text-decoration: none;
}

.icon-link .glyphicon {
    vertical-align: text-top;
}

.delete-link {
    color: #d75452;
}

.delete-link:hover {
    color: #c9302c;
}

.stripe-card-container {
    padding: 15px;
    background: #efefef;
    margin: 10px 0;
    font-size: 18px;
}

.invoices-panel .panel-title a {
    font-weight: normal;
    display: flex;
    justify-content: space-between;
}

.invoices-panel:hover {
    cursor:pointer;
}

.footer {
    flex-shrink: 0;
    padding: 15px;
    background: #efefef;
    margin-top: 15px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
}